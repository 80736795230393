import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Divider,
  darken,
  Tabs,
  Tab,
  IconButton,
  TextField,
  InputAdornment,
  Pagination,
  Stack,
} from "@mui/material";
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import { lighten } from "@material-ui/core";
import CopiedTooltip from "../components/common/CopiedTooltip";
import { openModal, closeModal } from "../features/slices/modalSlice";
import FlipNumbers from "react-flip-numbers";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CircularProgress from "@mui/material/CircularProgress";

import { selectCurrentToken } from "../features/slices/authSlice";

import Image from "../components/common/Image";
import Announcement from "../components/common/Announcement";
import ImageCarousel from "../components/common/ImageCarousel";
import PopupBanner from "../components/common/PopupBanner";
import CardCarousel from "../components/common/CardCarousel";
import TextCarousel from "../components/common/TextCarousel";
import Backdrop from "../components/common/Backdrop";
import AccordionComponent from "../components/common/Accordion";
import Sidebar from '../components/common/SideBar';
import { Link } from 'react-router-dom';
import SlotIcon from "../icons/header/SlotIcon";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  useGetGameCategoryQuery,
  useLazyGetGameProviderQuery,
  useGetGameProviderQuery,
  useLazyGetLaunchGameQuery,
  useLazyGetGameItemQuery
} from "../features/api/gameApiSlice";
import { useGetHomePageQuery, useGetPopupBannerQuery } from "../features/api/homeApiSlice";
import { useGetShareLinkQuery } from "../features/api/userApiSlice";

import CasinoIcon from "../icons/header/CasinoIcon";
import SportIcon from "../icons/header/SportIcon";
import FishingIcon from "../icons/header/FishingIcon";
import PromotionIcon from "../icons/header/PromotionIcon";
import VipIcon from "../icons/header/VipIcon";
import WalletCard from "../components/common/WalletCard";
import SabungIcon from "../icons/header/SabungIcon";
import LiveCasinoCarousel from "../components/common/LiveCasinoCarousel";
import DOMPurify from "dompurify";
import GameCard from "../components/Game/GameCard";
import * as Constant from "../features/constant";
import { ALT_TAG_ICON_REFERRAL } from "../features/constant";
import { useGetLiveMatchesQuery } from "../features/api/publicApiSlice";

const PATH = process.env.PUBLIC_URL;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [popupOpen, setPopupOpen] = useState(true);
  const [affiliatePopupOpen, setAffiliatePopupOpen] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedGameCategory, setSelectedGameCategory] = useState("");
  const [mobileSelectedGameCategory, setMobilSelectedGameCategory] =
    useState("SLOT");
  const isLoggedIn = useSelector(selectCurrentToken);
  const [number, setNumber] = useState(14020000);
  const [gameShownValue, setGameShownValue] = useState(0);
  const lang = localStorage.getItem("language");
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);
  const visibleItems = 4;
  const { gameCategoryCode, gameProviderCode } = useState("");
  const [currentProviderId, setCurrentProviderId] = useState(null);
  const [offset, setOffset] = useState(1);
  let pathname = window.location.pathname?.toLowerCase();
  const [domContentLoaded, setDOMContentLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [interactionBlocked, setInteractionBlocked] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0); // Track active slide


  const handleWheel = (event) => {
    event.preventDefault();

    // You can access event.deltaY to determine the direction of the scroll
    const delta = event.deltaY;

    // Add your logic to handle the scroll event
    // For example, you can update the scroll position based on the delta
    const newScrollPosition = scrollPosition + delta;

    // Make sure the new scroll position is within the bounds of your data
    const maxScrollPosition = additionalGameBarItems.length - visibleItems;
    const clampedScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollPosition));

    // Update the scroll position in your component state or wherever it's stored
    setScrollPosition(clampedScrollPosition);
  };

  const handleUpClick = () => {
    if (scrollPosition > 0) {
      setScrollPosition(scrollPosition - 1);
    }
  };

  const handleDownClick = () => {
    if (scrollPosition + visibleItems < additionalGameBarItems.length) {
      setScrollPosition(scrollPosition + 1);
    }
  };

  const handleListItemClick = (index) => {
    // Handle click events on list items, update scroll position based on the index
    setScrollPosition(index);
  };

  let customMenuItems = [{ name: 'Hot Game', code: 'HOT_GAME', image: process.env.REACT_APP_HEADER_LOGO + '/HOT_GAME.png' }].concat(Constant.getCustomMenuItems());

  const [additionalGameBarItems, setAdditionalGameBarItems] = useState(customMenuItems);

  const updateContent = (index) => {
    setSelectedRowIndex(index);
  };

  const scrollTable = (direction) => {
    const newIndex = selectedRowIndex + direction;
    if (newIndex >= 0 && newIndex < additionalGameBarItems.length) {
      setSelectedRowIndex(newIndex);
    }
  };
  const handleSelectCategory = (item) => {
    setSelectedGameCategory(item);
  };


  const walletCardScreens = [
    {
      label: t("walletcard.referral"),
      code: "Referral",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={process.env.REACT_APP_HOME_REFERRAL}
          alt={Constant.ALT_TAG_ICON_REFERRAL}
        />
      ),
    },
    {
      label: t("walletcard.appdownload"),
      code: "App",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={process.env.REACT_APP_HOME_APP_DOWNLOAD}
          alt={Constant.ALT_TAG_ICON_APP_DOWNLOAD}
        />
      ),
    },
    {
      label: t("walletcard.deposit"),
      code: "Deposit",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={process.env.REACT_APP_HOME_DEPOSIT}
          alt={Constant.ALT_TAG_ICON_DEPOSIT}
        />
      ),
    },
    {
      label: t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={process.env.REACT_APP_HOME_WITHDRAW}
          alt={Constant.ALT_TAG_ICON_WITHDRAW}
        />
      ),
    },
    // {
    //   label: t("walletcard.account"),
    //   code: "My Account",
    //   icon: (
    //       <Image
    //           width="35px"
    //           height="35px"
    //           src={process.env.REACT_APP_HOME_REFERRAL}
    //           alt={Constant.ALT_TAG_ICON_REFERRAL}
    //       />
    //   ),
    // },
    /*    ,
        {
          label: t("home.promotion"),
          code: "Promotion",
          icon: (
              <Image
                  width="40px"
                  height="40px"
                  src={PATH + `/assets/img/wallet/wallet_card/Promotion.png`}
                  alt={Constant.ALT_TAG_ICON_PROMO}
              />
          ),
        },
        {
          label: t("home.vip.title"),
          code: "Vip",
          icon: <VipIcon width={"40px"} height={"40px"} color={"#C0C0C0"} />,
        },*/
  ];
  const ulStyle = { color: 'white' };

  const homePageSeo = [
    <>
      <Typography fontWeight={800} color="white"><h1>{i18n.t("home.seo.title")}</h1></Typography>
      <Typography mt={2} color="white" dangerouslySetInnerHTML={{ __html: t("home.seo.content") }}></Typography>
    </>,
  ];

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isError: isGameCategoryError,
    isSuccess: isGameCategorySuccess,
    error: gameCategoryError,
  } = useGetGameCategoryQuery({ prefetch: true });

  const [
    getGameProvider,
    {
      data: gameProvider,
      isFetching: isGameProviderFetching,
      isLoading: isGameProviderLoading,
      isError: isGameProviderError,
      isSuccess: isGameProviderSuccess,
      error: gameProviderError,
    },
  ] = useLazyGetGameProviderQuery({ prefetch: true });

  const {
    data: casinoProvider,
    isFetching: isCasinoProviderFetching,
    isLoading: isCasinoProviderLoading,
    isError: isCasinoProviderError,
    isSuccess: isCasinoProviderSuccess,
    error: casinoProviderError,
  } = useGetGameProviderQuery({ category: "LIVE_CASINO", isHome: true }, true, {
    prefetch: true,
  });

  const {
    data: allGameProvider,
    isFetching: isAllGameProviderFetching,
    isLoading: isAllGameProviderLoading,
    isError: isAllGameProviderError,
    isSuccess: isAllGameProviderSuccess,
    error: allGameProviderError,
  } = useGetGameProviderQuery({ category: "" }, { prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  const {
    data: homePage,
    isFetching: isHomePageFetching,
    isLoading: isHomePageLoading,
    isError: isHomePagetError,
    isSuccess: isHomePageSuccess,
    error: homePageError,
  } = useGetHomePageQuery({ prefetch: true });

  const {
    data: popupBanner,
    isFetching: isPopupBannerFetching,
    isLoading: isPopupBannerLoading,
    isError: isPopupBannerError,
    isSuccess: isPopupBannerSuccess,
    error: PopupBannerError,
  } = useGetPopupBannerQuery({ prefetch: true });

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  const [
    getGameItem,
    {
      data: gameItem,
      isFetching: isGameItemFetching,
      isLoading: isGameItemLoading,
      isError: isGameItemError,
      isSuccess: isGameItemSuccess,
      error: gameItemError,
    },
  ] = useLazyGetGameItemQuery({ prefetch: true });

  const handlePageChange = (e, p) => {
    setOffset(p);
    window.scroll(0, 0);
  };

  useEffect(() => {

    if (isLaunchGameLoading || isLaunchGameFetching) {
      setShowLoading(true);
      setInteractionBlocked(true);
    }

    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }
      }
    }

    setTimeout(() => {
      setShowLoading(false);
      setInteractionBlocked(false); // Re-enable user interaction
    }, 10000);


  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess, launchGame]);

  const add = () => setNumber((number) => number + 1);

  useEffect(() => {
    setInterval(() => {
      add();
    }, 1000);
  }, []);

  useEffect(() => {
    if (mobileView && !selectedGameCategory) {
      let hasDefaultGameCategory = false;
      additionalGameBarItems.map((item, index) => {
        if (pathname.includes(item.code.toLowerCase().replace("_", "-"))) {
          setSelectedGameCategory(item.code);
          hasDefaultGameCategory = true;
        }
      });

      if (!hasDefaultGameCategory) {
        setSelectedGameCategory("HOT_GAME");
      }
    }

  }, [mobileView]);

  useEffect(() => {
    getGameProvider({
      category: !mobileView ? "SLOT" : (selectedGameCategory ? selectedGameCategory : undefined),
    });
  }, [selectedGameCategory, gameCategory, mobileView]);

  useEffect(() => {
    if (searchParams.get("refcode")) {
      localStorage.setItem("refCode", searchParams.get("refcode"));
      navigate(Constant.getPath("/register"));
    }
  }, []);

  // useEffect(() => {
  //   if (currentProviderId == null) {
  //     setCurrentProviderId(gameProvider?.result[0]?.id);
  //     }
  // });

  useEffect(() => {
    // setAppGameDisplay(false);
    getGameItem({
      category: "SLOT",
      offset: offset - 1,
      providerId: currentProviderId,
      keyword: "",
      group: currentProviderId == null ? "" : "ALL",
      hasProvider: currentProviderId != null
    });
  }, [offset, currentProviderId]);

  useEffect(() => {
    setOffset(1);
  }, [currentProviderId]);

  useEffect(() => {
    const handleDOMContentLoaded = () => {
      setDOMContentLoaded(true);
    };

    document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
    };
  }, []);

  const {
    data: liveMatches,
    isFetching: isLiveMatchesFetching,
    isLoading: isLiveMatchesLoading,
    isError: isLiveMatchesError,
    error: liveMatchesError,
  } = useGetLiveMatchesQuery();

  const [filteredLiveMatches, setFilteredLiveMatches] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (liveMatches?.result?.length) {
      setSelectedCategory(liveMatches.result[0].category);
    }
  }, [liveMatches]);

  useEffect(() => {
    if (liveMatches?.result?.length) {
      setSelectedCategory(liveMatches.result[0].category);
    }
  }, [liveMatches]);

  useEffect(() => {
    if (liveMatches?.result) {
      setFilteredLiveMatches(liveMatches.result.filter(match => match.category === selectedCategory));
    }

    setTimeout(() => {
      sliderRef.current?.slickGoTo(0); // Restore slide position
    }, 0);
  }, [selectedCategory]);


  const settings = {
    dots: true,
    infinite: filteredLiveMatches?.length > 1, // Disable loop if only 1 match
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    afterChange: (current) => setActiveSlide(current),
  }

  const popup = process.env.REACT_APP_POPUP;

  const closePopup = () => setPopupOpen(false);
  const openPopup = () => setPopupOpen(true);

  const closeAffiliatePopup = () => setAffiliatePopupOpen(false);
  const openAffiliatePopup = () => setAffiliatePopupOpen(true);

  useEffect(() => {
    const hidePopup = localStorage.getItem('hidePopup');
    if (hidePopup === 'true') {
      setPopupOpen(false);
    }
  }, []);

  if (
    isHomePageFetching ||
    isHomePageLoading ||
    isAllGameProviderFetching ||
    isAllGameProviderLoading ||
    isGameCategoryFetching ||
    isGameCategoryLoading
  ) {
    return <Backdrop />;
  }

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }

  const liveMatchesDisplay = (
    <Box
      paddingX={mobileView ? 2 : undefined}
      width="100%"
      display="flex"
      flexDirection="row"
      color="black"
      alignSelf="center"
      justifyContent="space-between"
    >
      <Typography
        width={mobileView ? "20%" : "100px"}
        color="white"
        fontSize={mobileView ? "12px" : "14px"}
        fontWeight="bold"
      >
        {filteredLiveMatches?.[activeSlide ?? 0]?.team1}
      </Typography>

      <Box paddingTop={4} textAlign="center">
        <Typography color="white" fontWeight="bold" fontSize={mobileView ? "10px" : "18px"}>
          {filteredLiveMatches?.[activeSlide ?? 0]?.gameDate}
        </Typography>
        <Typography color="white" fontSize={mobileView ? "9px" : "14px"}>
          {filteredLiveMatches?.[activeSlide ?? 0]?.gameTime}
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: mobileView ? "70px" : "80px",
            height: mobileView ? "25px" : "30px",
            fontWeight: "bold",
            fontSize: mobileView ? "8px" : "10px",
            alignSelf: "center",
          }}
          onClick={() => {
            navigate(Constant.getPath("/game/sportbook"));
          }}
        >
          {t("home.previewbutton")}
        </Button>
      </Box>

      <Typography
        width={mobileView ? "20%" : "100px"}
        color="white"
        fontSize={mobileView ? "12px" : "14px"}
        fontWeight="bold"
      >
        {filteredLiveMatches?.[activeSlide ?? 0]?.team2}
      </Typography>
    </Box>
  );

  const liveMatchesImages = (match) => (
    <Box
      display="flex"
      alignItems="center"
      paddingX={mobileView ? "15px" : "0px"}
      justifyContent="space-between"
    >
      <Image width={mobileView ? 65 : 100} height={mobileView ? 65 : 100} src={match.team1Logo} />
      <Image width={mobileView ? 65 : 100} height={mobileView ? 65 : 100} src={match.team2Logo} />
    </Box>
  );

  return (

    <>
      <style>
        {`
        .slick-dots {
          margin-bottom: ${mobileView ? "-80px" : "-110px"};
        }
       
        .slick-dots li button:before {
          color: grey !important; 
          opacity: 1 !important; 
        }

        .slick-dots li.slick-active button:before {
          color: white !important; 
        }
      `}
      </style>
      {/* Loading Overlay */}
      <Modal open={showLoading}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              color: "#fff",
            }}
          >
            {/* current image not compatible, doesnt show correctly*/}
            {/* <img
              src="/favicon.ico"
              alt="Website Logo"
              style={{ width: "100px", marginBottom: "20px" }}
            /> */}
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      </Modal>

      {/* Global Block Interaction */}
      {interactionBlocked && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1200,
            pointerEvents: "none", // Disable pointer events
          }}
        />
      )}


      <Box pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
        <Divider sx={{ backgroundColor: '#F9B233', width: '100%' }} />
        {/* Banner */}
        <ImageCarousel
          banners={
            mobileView
              ? homePage?.result?.mobileBanners
              : homePage?.result?.banners
          }
          navigation={false}
          spaceBetween={0}
          alt={Constant.ALT_TAG_BANNER}
        />
        {/* Announcement */}
        {homePage?.result?.announcements && (
          <Announcement announcementDetails={homePage?.result?.announcements} />
        )}


        {/*Mobile View Wallet Card */}
        {mobileView && (
          <div>
            <Box>
              <WalletCard screens={walletCardScreens} token={isLoggedIn} />
            </Box>
            <Divider />
          </div>
        )}
        <Divider sx={{ backgroundColor: '#F9B233', width: '100%' }} />
        <br />
        {mobileView && (
          <div style={{ display: 'flex' }}>
            <Box pl={0.5} pr={0} mt={0} style={{ flex: '10%', overflowY: 'auto' }}>
              <>
                <button
                  onClick={handleUpClick}
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: '#000000',   //#073F62
                    width: '100%',
                    height: '10px',
                    border: '1px solid black',
                  }}
                >
                  <ArrowDropUp style={{ color: 'white', }} />
                </button>

                {/* Left Sidebar */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',  // Align content at the top
                    overflowY: 'auto',  // Enable vertical scrolling
                    overflowX: 'hidden',  // Hide horizontal scrollbar
                    position: 'relative',
                    // width: '65px',
                    maxHeight: '480px',  // Set a maximum height for the content area
                    backgroundColor: '#000000',   //#073F62
                    scrollbarWidth: 'thin', // For Firefox
                    scrollbarColor: 'transparent transparent', // For Firefox
                    '&::-webkit-scrollbar': {
                      width: '0.2em',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'transparent',
                    },
                    //paddingRight:'4px',
                    borderRadius: '10px',
                  }}
                >

                  {/* Scrollable Content Area */}
                  {/*   {additionalGameBarItems.map((item, index) => (
                            <Button
                                key={index}
                                onClick={() => setSelectedGameCategory(item.code)}
                                style={{
                                  backgroundColor: selectedGameCategory == item.code ? theme.palette.button.danger.main : "#212529",
                                  borderRadius: '10px',
                                  marginBottom: '4px',
                                  minWidth: "0px"
                                }}
                            >
                              <img src={item.image} alt={item.name} style={{ width: '100%', height: '100%' }} />
                            </Button>

                        ))}*/}

                  <div>
                    {selectedGameCategory && (
                      <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                        <Button
                          onClick={() => handleSelectCategory(null)}
                          style={{
                            backgroundColor: theme.palette.button.danger.main,
                            borderRadius: '10px',
                            marginBottom: '4px',
                            minWidth: "0px",
                            zIndex: 10
                          }}
                        >
                          <img src={additionalGameBarItems.find(item => item.code === selectedGameCategory).image}
                            alt={additionalGameBarItems.find(item => item.code === selectedGameCategory).name}
                            style={{ width: '100%', height: '100%' }} />
                        </Button>
                      </div>
                    )}

                    <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - heightOfTheStickyHeader)' }}>
                      {additionalGameBarItems
                        .filter((item) => item.code !== selectedGameCategory)
                        .map((item, index) => (
                          <Button
                            key={index}
                            onClick={() => {
                              window.fbq('trackCustom', 'mobile_' + item.code);
                              handleSelectCategory(item.code)
                            }}
                            style={{
                              backgroundColor: "#212529",  //#011630
                              borderRadius: '10px',
                              marginBottom: '4px',
                              minWidth: "0px",
                              opacity: selectedGameCategory === item.code ? 0 : 1
                            }}
                          >
                            <img src={item.image} alt={item.name} style={{ width: '100%', height: '100%' }} />
                          </Button>
                        ))}
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleDownClick}
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    background: '#000000',   //#073F62
                    width: '100%',
                    height: '10px',
                    border: '1px solid black',

                  }}
                >
                  <ArrowDropDown style={{ color: 'white' }} />
                </button>
              </>
            </Box>

            {/* Right Content (Game Provider) */}
            <div style={{
              flex: '70%', background: '#000000', display: 'flex', flexDirection: 'column', height: '540px', overflowY: 'auto',   //#073F62
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'transparent transparent', // For Firefox
              transform: 'scale(0.95)',
              transformOrigin: 'top',
              '&::-webkit-scrollbar': {
                width: '0.4em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
              },
            }}>
              <Box p={0} mt={0} style={{ flex: '80%', overflowY: 'auto' }}>
                {selectedGameCategory == 'HOT_GAME' && !isHomePageFetching && !isHomePageLoading && isHomePageSuccess && (
                  <>
                    <Grid container>
                      {homePage?.result?.recommendGame?.map((game, idx) => (
                        <Grid
                          key={idx}
                          item
                          xs={4}
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            onClick={() => {
                              //TODO cater game provider in Hot Game section
                              let hasGameList = false;
                              if (hasGameList) {
                                navigate(
                                  Constant.getPath(`/game/${selectedGameCategory?.toLowerCase()}`),
                                  {
                                    state: {
                                      providerId: game.providerId,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  // dispatch(
                                  //     openModal({
                                  //       title: t("dialog.proceedtologin"),
                                  //       button: [
                                  //         {
                                  //           label: t("dialog.login"),
                                  //           onSubmit: () => {
                                  //             dispatch(closeModal());
                                  navigate(Constant.getPath("/login"));
                                  //       },
                                  //     },
                                  //   ],
                                  // })
                                  // );
                                } else {
                                  window.fbq('trackCustom', 'LaunchHot_' + game?.name);

                                  getLaunchGame({
                                    id: game?.id,
                                    type: game?.gameType,
                                    category: game.categoryCode,
                                    isMobile: mobileView
                                  });

                                  if (game?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }
                                }
                              }
                            }}
                            sx={{
                              margin: '3px', // Adjust the value to set the desired spacing
                            }}
                          >
                            <Box display="flex" flexDirection="column" position="relative">
                              <Image src={game.homeThumbnailUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + game.name} sx={{ borderRadius: "5px" }} />
                              {/* <Typography
                                    color="white"
                                    fontSize={9}
                                    textAlign="left"
                                    fontWeight={500}
                                    sx={{
                                      position: "absolute",
                                      bottom: "5px",
                                      left: "5px",
                                      maxWidth: "120px",
                                      zIndex: 1,
                                    }}
                                >
                                  {game.name}
                                </Typography>*/}
                              <Typography
                                color="white"
                                fontSize={9}
                                textAlign="left"
                                fontWeight={400}
                                sx={{
                                  marginTop: "5px",
                                }}
                              >
                                {game.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                {selectedGameCategory != 'HOT_GAME' && !isGameProviderFetching && !isGameProviderLoading && isGameProviderSuccess && (
                  <>
                    <Grid container>
                      {gameProvider?.result?.map((provider, idx) => (
                        <Grid
                          key={idx}
                          item
                          xs={selectedGameCategory === 'SLOT' || selectedGameCategory === 'FISH_HUNTER' ? 6 : 12}
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                (category) =>
                                  category.code === selectedGameCategory
                              )?.hasGameList;
                              if (hasGameList) {
                                window.fbq('trackCustom', 'click_' + provider?.name);

                                navigate(
                                  Constant.getPath(`/game/${selectedGameCategory?.toLowerCase()}`),
                                  {
                                    state: {
                                      providerId: provider.id,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  // dispatch(
                                  //     openModal({
                                  //       title: t("dialog.proceedtologin"),
                                  //       button: [
                                  //         {
                                  //           label: t("dialog.login"),
                                  //           onSubmit: () => {
                                  //             dispatch(closeModal());
                                  if (provider?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }

                                  navigate(Constant.getPath("/login"));
                                  //       },
                                  //     },
                                  //   ],
                                  // })
                                  // );
                                } else {
                                  getLaunchGame({
                                    id: provider?.id,
                                    type: provider?.gameType,
                                    category: selectedGameCategory,
                                    isMobile: mobileView
                                  });
                                  if (provider?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }
                                }
                              }
                            }}
                            sx={{
                              margin: '3px', // Adjust the value to set the desired spacing
                            }}
                          >
                            <Box display="flex" flexDirection="column">
                              <Image src={provider.homeThumbnailUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name} />
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>
            </div>
          </div>
        )}

        {/* SLOT Game Provider Icons */}
        {!mobileView && (
          <Grid container spacing={3} sx={{
            margin: "0 auto",
            marginTop: "15px",
            marginBottom: "25px",
            overflowX: "scroll",
            width: "90%",
            flexWrap: "nowrap",
            textAlign: "center",
            '&::-webkit-scrollbar': { display: "none" }
          }}>
            <Grid key={-1} item>
              <Box
                sx={{
                  border: 1,
                  display: "flex",
                  justifyContent: "center",
                  height: "50px",
                  width: "50px",
                  alignItems: "center",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  cursor: "pointer",
                  borderRadius: "50%",
                  borderColor:
                    currentProviderId === null
                      ? theme.palette.primary.main
                      : darken(theme.palette.background.paper, 0.2),
                  overflow: "hidden",
                }}
                onClick={() => {
                  setCurrentProviderId(null);
                }}
              >
                <Typography color="white" fontWeight={600}>
                  ALL
                </Typography>

              </Box>
              <Typography
                color={currentProviderId === null ? theme.palette.tertiary.main : "white"}
                fontSize="12px"
                pt="8px"
              >
                {"ALL"}
              </Typography>
            </Grid>
            {gameProvider?.result?.map((provider, index) => (
              <Grid key={index} item sx={{ pl: "15px !important" }}>
                <Box
                  sx={{
                    border: 1,
                    display: "flex",
                    justifyContent: "center",
                    height: "50px",
                    width: "50px",
                    alignItems: "center",
                    boxSizing: "border-box",
                    wordWrap: "break-word",
                    cursor: "pointer",
                    borderRadius: "50%",
                    borderColor:
                      currentProviderId === provider.id
                        ? theme.palette.primary.main
                        : darken(theme.palette.background.paper, 0.2),
                    overflow: "hidden",
                    margin: "0 auto",
                  }}
                  onClick={() => {
                    window.fbq('trackCustom', 'click_' + provider?.name);

                    setCurrentProviderId(provider.id);
                    // navigate(Constant.getPath("/game/" + gameCategory + "/" + provider.name.replaceAll(" ", "-").toLowerCase()));
                  }}
                >
                  <img
                    src={
                      currentProviderId === provider.id
                        ? provider.logoSelectedUrl
                        : provider.logoUrl
                    }
                    alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}
                  />

                </Box>
                <Typography
                  color={currentProviderId === provider.id ? theme.palette.tertiary.main : "white"}
                  fontSize="12px"
                  pt="8px"
                >
                  {provider.name.split(" ")[0]}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {/* { Game Item List } */}
        {!mobileView && (
          <Box pb={5}>
            {!isGameItemLoading &&
              isGameItemSuccess && (
                <>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    mt={mobileView ? 0 : 1}
                    width="90%"
                    margin="0 auto"
                  >
                    {gameItem?.result?.data?.map((game, index) => (
                      <>
                        {(
                          <Grid
                            item
                            xs={3}
                            sm={2}
                            md={1.5}
                            key={index}
                            display="flex"
                            justifyContent="center"
                          >
                            <GameCard
                              imageUrl={game?.thumbnailUrl}
                              imageWidth={
                                mobileView
                                  ? "80px"
                                  : tabletView
                                    ? "120px"
                                    : "150px"
                              }
                              imageHeight={
                                mobileView
                                  ? "80px"
                                  : tabletView
                                    ? "120px"
                                    : "150px"
                              }
                              height="150px"
                              alt={Constant.ALT_TAG_GENERAL_GAME + game.name}
                              onClick={() => {
                                if (!isLoggedIn) {
                                  if (game?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }

                                  dispatch(
                                    openModal({
                                      title: t("dialog.proceedtologin"),
                                      button: [
                                        {
                                          label: t("dialog.login"),
                                          onSubmit: () => {
                                            dispatch(closeModal());
                                            if (mobileView) {
                                              navigate(Constant.getPath("/login"));
                                            }
                                          },
                                        },
                                      ],
                                    })
                                  );
                                } else {
                                  // if (game?.disabled) {
                                  //   dispatch(
                                  //     openModal({
                                  //       title: t("game.gameunavailable"),
                                  //     })
                                  //   );
                                  // } else {
                                  // setAppProviderName(
                                  //   gameProvider?.result?.find(
                                  //     (provider) =>
                                  //       provider?.id === game?.providerId
                                  //   )?.name
                                  // );
                                  getLaunchGame({
                                    id: game?.id,
                                    type: game?.gameType,
                                    isMobile: mobileView
                                  });

                                  if (game?.name.toLowerCase().includes("evolution")) {
                                    window.fbq('trackCustom', 'ClickEvolution');
                                  }
                                  // }
                                }
                              }}
                              title={game?.name}
                              subtitle={
                                gameProvider?.result?.find(
                                  (provider) => provider?.id === game?.providerId
                                )?.name
                              }
                              description={{}}
                            />
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                  {gameItem?.result?.totalPage !== 0 && (
                    <Stack
                      mt={tabletView ? 0 : 2}
                      spacing={2}
                      size="small"
                      alignItems="center"
                    >
                      <Pagination
                        onChange={handlePageChange}
                        count={gameItem?.result?.totalPage}
                        shape="rounded"
                        page={offset}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "white",
                            "&.Mui-selected": {
                              background: lighten(
                                theme.palette.background.paper,
                                0.02
                              ),
                              color: "white",
                            },
                          },
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
            {(
              <Grid container spacing={3}>
                {gameProvider?.result?.map((provider, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    md={2}
                    key={index}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (!isLoggedIn) {
                        if (provider?.name.toLowerCase().includes("evolution")) {
                          window.fbq('trackCustom', 'ClickEvolution');
                        }
                        dispatch(
                          openModal({
                            title: t("dialog.proceedtologin"),
                            button: [
                              {
                                label: t("dialog.login"),
                                onSubmit: () => dispatch(closeModal()),
                              },
                            ],
                          })
                        );
                      } else {
                        // setAppProviderName(provider?.name);
                        getLaunchGame({
                          id: provider?.id,
                          type: provider?.gameType,
                          category: gameCategory,
                          isMobile: mobileView
                        });
                        if (provider?.name.toLowerCase().includes("evolution")) {
                          window.fbq('trackCustom', 'ClickEvolution');
                        }
                      }
                    }}
                  >
                    {/* <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box overflow="hidden">
                        <Image hovered={true} src={provider.providerImgUrl} sx={{ borderRadius: 4 }} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}/>
                      </Box>
                      <Typography
                        color={theme.palette.primary.main}
                        fontSize={13}
                        fontWeight={800}
                        mt={2}
                      >
                        {provider.name}
                      </Typography>
                    </Box> */}
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}

        {
          liveMatches.result && liveMatches.result.length > 0 && (
            <Grid
              container
              spacing={3}
              sx={{
                marginTop: "15px",
                marginBottom: mobileView ? "35px" : "60px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} key={-1} item>
                <Box display={'flex'} flexDirection={'row'} paddingLeft={mobileView ? "20px" : "100px"}>
                  <Typography fontSize={mobileView ? "12px" : "14px"} fontWeight={'bold'} color='white'>
                    {t("home.livematches")}
                  </Typography>
                  <Grid
                    color='white'
                    gap={mobileView ? 1 : 2}
                    marginLeft={mobileView ? "25px" : "70px"}
                    display={'flex'}
                    flexDirection={'row'}
                  >
                    {[...new Set(liveMatches.result.map(match => match.category))].map((category) => (
                      <Grid
                        item
                        fontSize={mobileView ? "11px" : "14px"}
                        sx={{
                          cursor: 'pointer',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          backgroundColor: selectedCategory === category ? 'red' : 'transparent',
                        }}
                        onClick={() => setSelectedCategory(category)}
                      >
                        {category}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>

              <Grid
                item
                sx={{
                  width: '100%',
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '5px !important',
                  position: 'relative',
                }}
              >
                <img
                  src={mobileView ? process.env.REACT_APP_LIVE_MATCHES_MOBILE : process.env.REACT_APP_LIVE_MATCHES}
                  alt='Live Preview'
                  style={{ width: '100%', height: mobileView ? '230px' : 'auto' }}
                />
                {filteredLiveMatches && filteredLiveMatches.length > 1 ? (
                  <>
                    <Box display="flex" justifyContent="center" position="absolute" top={mobileView ? "2%" : "0%"}>
                      <Image width={mobileView ? 115 : 250} height={mobileView ? 65 : 145} src={filteredLiveMatches?.[activeSlide ?? 0]?.league} />
                    </Box>
                    <Box position="absolute" bottom={mobileView ? "-3%" : "6%"} sx={{ width: mobileView ? "85%" : "50%" }}>
                      <Slider ref={sliderRef} {...settings}>
                        {filteredLiveMatches.map((match) => (
                          <Box>{liveMatchesImages(match)}</Box>
                        ))}
                      </Slider>
                      {liveMatchesDisplay}
                    </Box>
                  </>
                ) : (
                  filteredLiveMatches.map((match) => (
                    <>
                      <Box display="flex" justifyContent="center" position="absolute" top={mobileView ? "2%" : "0%"}>
                        <Image width={mobileView ? 115 : 250} height={mobileView ? 65 : 145} src={filteredLiveMatches?.[activeSlide ?? 0]?.league} />
                      </Box>
                      <Box position="absolute" bottom={mobileView ? "-3%" : "6%"} sx={{ width: mobileView ? "85%" : "50%" }}>
                        {liveMatchesImages(match)}
                        {liveMatchesDisplay}
                      </Box>
                    </>
                  ))
                )}
              </Grid>
            </Grid>
          )
        }

        {/* Description picture under banner */}
        {/*!mobileView && (
            <Box
                sx={{
                  background: `url('${homePage?.webBackground[0]?.thumbnailUrl}')`,
                }}
            >
              <Image src={homePage?.result?.common?.web_promote_banner} alt={Constant.ALT_TAG_STEPS}/>
            </Box>
        )*/}
        {/* Progressive jackpot */}
        {/*!mobileView && (
            <Box
                sx={{
                  background: `url('${homePage?.webBackground[1]?.thumbnailUrl}')`,
                }}
            >
              <Container>
                <Box
                    sx={{
                      position: "relative",
                      zIndex: 1,
                    }}
                    pt={5}
                >
                  <Image src={homePage?.result?.common?.jackpot} alt={Constant.ALT_TAG_JACKPOT} />
                  <Box
                      sx={{
                        position: "absolute",
                        top: tabletView ? "68%" : "67%",
                        left: "53%",
                        transform: "translate(-50%, -50%)",
                      }}
                  >
                    <FlipNumbers
                        height={70}
                        width={tabletView ? 57 : 84}
                        color={theme.palette.secondary.main}
                        background="transparent"
                        play
                        numberStyle={{
                          fontSize: tabletView ? 30 : 40,
                          fontWeight: "bold",
                        }}
                        nonNumberStyle={{
                          fontSize: 40,
                          color: theme.palette.secondary.main,
                        }}
                        className="flip-numbers"
                        numbers={`${number}`}
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
        )*/}
        {/* New Live Casino */}
        {/*!mobileView && (
            <Box
                sx={{
                  background: `url('${homePage?.webBackground[2]?.thumbnailUrl}')`,
                }}
            >
              <Container>
                <Box>
                  <Typography
                      pb={5}
                      textAlign="center"
                      variant="h5"
                      fontWeight="800"
                      color={"white"}
                  >
                    {t("home.livecasino")}
                  </Typography>
                  <LiveCasinoCarousel
                      data={{
                        result: casinoProvider?.result,
                        allGameProvider: allGameProvider?.result,
                        gameCategory: gameCategory?.result,
                      }}
                      slidesPerView={!tabletView ? 5 : 4}
                      pagination={false}
                      navigation={false}
                      spaceBetween={20}
                  />
                </Box>
              </Container>
            </Box>
        )/*}
        {/* New Hot Games */}
        {/*!mobileView && homePage?.result?.featureGames && (
            <Box
                sx={{
                  background: `url('${homePage?.webBackground[3]?.thumbnailUrl}')`,
                }}
            >
              <Container>
                <Typography
                    pt={8}
                    textAlign="center"
                    variant="h5"
                    fontWeight="800"
                    color="white"
                >
                  {t("home.hotgames")}
                </Typography>

                <Box
                    pt={4}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                  {!tabletView && (
                      <Box
                          sx={{
                            display: "flex",
                            alignSelf: "flex-start",
                            textAlign: "center",
                            maxWidth: "50%",
                            mr: 2,
                            borderRadius: 4
                          }}
                      >
                        <img src={process.env.REACT_APP_HOT_GAMES_PIC} alt={Constant.ALT_TAG_HOME_HOT_GAME}/>
                      </Box>
                  )}
                  <Box
                      display="flex"
                      flexDirection="column"
                      maxWidth={!tabletView ? "80%" : "100%"}
                  >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        onChange={(event, val) => setGameShownValue(val)}
                        sx={{
                          borderBottom: `1px solid white`,
                        }}
                    >
                      <Tab
                          label={"RECOMMEND GAMES"}
                          sx={{
                            color: "white",
                            backgroundColor:
                                gameShownValue === 0 && theme.palette.primary.main,
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            fontWeight: 600,
                          }}
                          disableRipple
                      />
                      <Tab
                          label={"HOT GAMES"}
                          sx={{
                            color: "white",
                            backgroundColor:
                                gameShownValue === 1 && theme.palette.primary.main,
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5,
                            fontWeight: 600,
                          }}
                          disableRipple
                      />
                    </Tabs>
                    <TabPanel value={gameShownValue} index={0}>
                      <CardCarousel
                          imageWidth={tabletView ? "122px" : "131px"}
                          imageHeight={tabletView ? "122px" : "131px"}
                          data={{
                            result: homePage?.result?.recommendGame,
                            allGameProvider: allGameProvider?.result,
                            gameCategory: gameCategory?.result,
                          }}
                          slidesPerView={5}
                          pagination={false}
                          navigation={false}
                          spaceBetween={0}
                          rows={2}
                      />
                    </TabPanel>
                    <TabPanel value={gameShownValue} index={1}>
                      <CardCarousel
                          imageWidth={tabletView ? "122px" : "131px"}
                          imageHeight={tabletView ? "122px" : "131px"}
                          data={{
                            result: homePage?.result?.featureGames,
                            allGameProvider: allGameProvider?.result,
                            gameCategory: gameCategory?.result,
                          }}
                          slidesPerView={5}
                          pagination={false}
                          navigation={false}
                          spaceBetween={0}
                          rows={2}
                      />
                    </TabPanel>
                  </Box>
                </Box>
              </Container>
            </Box>
        )*/}
        {/* Promotion & Affiliate */}
        {/*homePage?.result?.bonuses && homePage?.result?.common?.affiliate && !mobileView &&(
            <Box
                sx={{
                  background: mobileView
                      ? `url('${homePage?.mobileBackground[1]?.thumbnailUrl}')`
                      : `url('${homePage?.webBackground[5]?.thumbnailUrl}')`,
                }}
            >
              <Container>
                <Box pt={1} pb={5}>
                  <Grid container spacing={0}>
                    <Grid item xs={!mobileView ? 6 : 12}>
                      {!mobileView && (
                          <Typography
                              mb={3}
                              textAlign="center"
                              variant="h5"
                              fontWeight="800"
                              color={"white"}
                          >
                            {t("home.promotion")}
                          </Typography>
                      )}
                      {!mobileView && (
                          <ImageCarousel
                              banners={homePage?.result?.bonuses}
                              slidesPerView={1}
                              pagination={true}
                              imageStyle={{ borderRadius: 3 }}
                              onClick={() => navigate(Constant.getPath("/promotion"))}
                          />
                      )}
                    </Grid>
                    {!mobileView &&  (
                        <Grid item xs={!mobileView ? 6 : 12}>
                      <Typography
                          mb={3}
                          textAlign="center"
                          variant="h5"
                          fontWeight="800"
                          color={"white"}
                      >
                        {t("home.affiliate")}
                      </Typography>
                      <Image
                          sx={{ borderRadius: 3, cursor: "pointer" }}
                          src={homePage?.result?.common?.affiliate}
                          alt={Constant.ALT_TAG_ADS_AFFL}
                          // onClick={() => navigate("/affiliate")}
                          onClick={() => {
                            dispatch(
                                openModal({
                                  title: t("home.affiliateContent"),
                                  content: (
                                      <>
                                        <div
                                            style={{paddingBottom: "30px"}}
                                            dangerouslySetInnerHTML={{
                                              __html: DOMPurify.sanitize(
                                                  homePage?.result?.affiliate_content
                                              ),
                                            }}
                                        ></div>
                                        {isLoggedIn && (
                                            <div style={{ display: 'flex', justifyContent: 'center',
                                              position: "absolute", bottom: 0, width: "100%", padding: "20px",
                                              background: theme.palette.background.paper, left: 0 }}>
                                              <TextField
                                                  label={t("walletcard.myaccountpage.referrallink")}
                                                  value={shareLink?.result?.url}
                                                  InputLabelProps={{ shrink: true }}
                                                  disabled
                                                  type="text"
                                                  size="small"
                                                  sx={{ width: "400px" }}
                                                  InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                          <IconButton
                                                              disableRipple
                                                              sx={{
                                                                "&.MuiButtonBase-root:hover": {
                                                                  backgroundColor: "transparent",
                                                                },
                                                              }}
                                                          >
                                                            <CopiedTooltip text={shareLink?.result?.url} />
                                                          </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                  }}
                                              />
                                            </div>
                                        )}
                                      </>
                                  ),
                                  button: !isLoggedIn
                                      ? [
                                        {
                                          label: t("promotion.login"),
                                          onSubmit: () => {
                                            dispatch(closeModal());
                                            if (mobileView) {
                                              navigate(Constant.getPath("/login"));
                                            } else {
                                              dispatch(
                                                  openModal({
                                                    title: t("dialog.proceedtologin"),
                                                    button: [
                                                      {
                                                        label: t("dialog.login"),
                                                        onSubmit: () =>
                                                            dispatch(closeModal()),
                                                      },
                                                    ],
                                                  })
                                              );
                                            }
                                          },
                                        },
                                        {
                                          label: t("promotion.register"),
                                          onSubmit: () => {
                                            dispatch(closeModal());
                                            navigate(Constant.getPath("/register"));
                                          },
                                        },
                                      ]
                                      : [],
                                })
                            );
                          }}
                      />
                    </Grid>
                    )}
                  </Grid>
                </Box>
              </Container>
            </Box>
                        )*/}
        {/*!mobileView && (
            <Box
                sx={{
                  background: `url('${homePage?.webBackground[1]?.thumbnailUrl}')`,
                }}
            >
              <Container>
                <Box
                    sx={{
                      position: "relative",
                      zIndex: 1,
                    }}
                    pt={5}
                >
                  <Image src={process.env.REACT_APP_HOME_APP_DOWNLOAD} alt={Constant.ALT_TAG_STEPS}/>
                </Box>
              </Container>
            </Box>
        )*/}

        {/* Game Provider for web view */}
        {/* {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={6} display="flex" flexDirection="column">
              <Grid
                container
                display="flex"
                justifyContent="center"
                sx={{
                  background: theme.palette.primary.main,
                }}
                // p={2}
              >
                <Grid item xs={2}>
                  <Box
                    sx={{
                      backgroundColor: darken(theme.palette.primary.main, 0.2),
                      height: "40px",
                      borderRadius: {
                        borderBottomLeftRadius: 40,
                        borderBottomRightRadius: 40,
                      },
                      mx: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="relative"
                      sx={{
                        "&:hover": {
                          background: "transparent",
                        },
                        cursor: "pointer",
                        position: "relative",
                        top: "10%",
                      }}
                      onClick={() => setSelectedGameCategory("")}
                      alignItems="center"
                    >
                      {selectedGameCategory === "" && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -5,
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderBottom: "2px solid",
                            width: "20%",
                            borderColor: theme.palette.secondary.main,
                          }}
                        />
                      )}
                      <Typography
                        color={
                          selectedGameCategory === ""
                            ? theme.palette.secondary.main
                            : "white"
                        }
                        textAlign="center"
                        fontWeight={600}
                      >
                        {t("home.allgames")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {gameCategory?.result.map((item, index) => (
                  <Grid item key={index} xs={2}>
                    <Box
                      sx={{
                        backgroundColor: darken(
                          theme.palette.primary.main,
                          0.2
                        ),
                        height: "40px",
                        borderRadius: {
                          borderBottomLeftRadius: 40,
                          borderBottomRightRadius: 40,
                        },
                        mx: 1,
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                          },
                          position: "relative",
                          top: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedGameCategory(item.code)}
                        alignItems="center"
                      >
                        <>
                          {item.code === selectedGameCategory && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: -5,
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderBottom: "2px solid",
                                width: "20%",
                                borderColor: theme.palette.secondary.main,
                              }}
                            />
                          )}
                          <Typography
                            color={
                              item.code === selectedGameCategory
                                ? theme.palette.secondary.main
                                : "white"
                            }
                            textAlign="center"
                            fontWeight={600}
                          >
                            {item?.label}
                          </Typography>
                        </>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box border={1} p={2} borderColor={theme.palette.primary.main}>
                <Grid container>
                  {!isGameProviderFetching &&
                    !isGameCategoryLoading &&
                    isGameProviderSuccess && (
                      <>
                        <Grid item sm={5} md={4}>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                (category) =>
                                  category.code === selectedGameCategory
                              )?.hasGameList;
                              if (hasGameList) {
                                navigate(
                                  `/game/${selectedGameCategory.toLowerCase()}`,
                                  {
                                    state: {
                                      providerId: gameProvider?.result[0]?.id,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  dispatch(
                                    openModal({
                                      title: t("dialog.proceedtologin"),
                                      button: [
                                        {
                                          label: t("dialog.login"),
                                          onSubmit: () =>
                                            dispatch(closeModal()),
                                        },
                                      ],
                                    })
                                  );
                                } else {
                                  getLaunchGame({
                                    id: gameProvider?.result[0]?.id,
                                    type: gameProvider?.result[0]?.gameType,
                                    category: selectedGameCategory,
                                  });
                                }
                              }
                            }}
                          >
                            <Image
                              width="275px"
                              height="275px"
                              src={gameProvider?.result[0]?.thumbnailUrl}
                            />
                          </Box>
                        </Grid>
                        <Grid item sm={7} md={8} container>
                          <Grid container>
                            {gameProvider?.result?.map((provider, idx) => (
                              <>
                                {idx !== 0 && idx <= 6 && (
                                  <Grid item sm={4} md={2}>
                                    <Box
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let hasGameList =
                                          gameCategory?.result?.find(
                                            (category) =>
                                              category.code ===
                                              selectedGameCategory
                                          )?.hasGameList;
                                        if (hasGameList) {
                                          navigate(
                                            `/game/${selectedGameCategory.toLowerCase()}`,
                                            {
                                              state: {
                                                providerId: provider.id,
                                              },
                                            }
                                          );
                                        } else {
                                          if (!isLoggedIn) {
                                            dispatch(
                                              openModal({
                                                title: t(
                                                  "dialog.proceedtologin"
                                                ),
                                                button: [
                                                  {
                                                    label: t("dialog.login"),
                                                    onSubmit: () =>
                                                      dispatch(closeModal()),
                                                  },
                                                ],
                                              })
                                            );
                                          } else {
                                            getLaunchGame({
                                              id: provider?.id,
                                              type: provider?.gameType,
                                              category: selectedGameCategory,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <Image
                                        width="110px"
                                        height="110px"
                                        src={provider.thumbnailUrl}
                                      />
                                    </Box>
                                  </Grid>
                                )}
                              </>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )} */}
        {/* Live Casino */}
        {/* {!mobileView && homePage?.result?.liveCasino && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
              <Typography
                pb={3}
                textAlign="center"
                variant="h5"
                fontWeight="800"
                color={"white"}
              >
                {t("home.livecasino")}
              </Typography>
              <ImageCarousel
                banners={homePage?.result?.liveCasino.slice(1)}
                slidesPerView={3}
                spaceBetween={0}
                navigation={false}
                autoHeight={true}
                loop={true}
                effect="coverflow"
                pagination={false}
                onClick={() => navigate("/game/live_casino")}
              />
            </Box>
          </Container>
        </Box>
      )} */}
        {/* Feature */}
        {/* {!mobileView && homePage?.result?.common?.web_wallet_step && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[4]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={4}>
              <Box
                sx={{
                  p: 1,
                  background: `url(${process.env.REACT_APP_WALLET_STEP_BG})`,
                }}
              >
                <Typography
                  pb={3}
                  textAlign="center"
                  variant="h5"
                  fontWeight="800"
                >
                  {t("home.feature.title")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_1} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.optimizedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.optimizeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      pt={1}
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_2} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.speedytitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.speedydesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_3} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.certifiedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.certifieddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_4} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.safeguardedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.safeguardeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )} */}
        {/* Mobile view feature banner */}
        {mobileView && (
          <Box
            sx={{
              background: `url('${homePage?.mobileBackground[2]?.thumbnailUrl}')`,
            }}
          >
            <Container>
              <Box pb={11}>
                <Image src={homePage?.result?.walletSteps} alt={Constant.ALT_TAG_STEPS} />
              </Box>
            </Container>
          </Box>
        )}
        {/* Misc section */}
        {/* {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={2} pb={11}>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/about")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.aboutus")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/vip")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.vip")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => null}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.affiliate")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => null}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.livechat")}
              </Typography>
              <Divider />
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/terms")}
                fontWeight={600}
                py={1}
              >
                {t("home.misc.terms")}
              </Typography>
              <Divider />
            </Box>
          </Container>
        </Box>
      )} */}
        {/* SEO text */}
        {!mobileView && (
          <Box
            sx={{
              background: mobileView
                ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`
                : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,
            }}
          >
            <Container>
              <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
                <TextCarousel
                  html={homePageSeo}
                  slidesPerView={1}
                  pagination={true}
                  spaceBetween={0}
                />
              </Box>
            </Container>
          </Box>
        )}
        {/* Accordion of terms */}
        {/*{!mobileView && (lang == "en" || !lang) && (*/}
        {/*    <Box*/}
        {/*        sx={{*/}
        {/*          background: mobileView*/}
        {/*              ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`*/}
        {/*              : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,*/}
        {/*        }}*/}
        {/*    >*/}
        {/*      <Container>*/}
        {/*        <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>*/}
        {/*          <AccordionComponent title={t("home.t&c.firsttitle")} >*/}
        {/*            {t("home.t&c.firstdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.secondtitle")}>*/}
        {/*            {t("home.t&c.seconddesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.thirdtitle")}>*/}
        {/*            {t("home.t&c.thirddesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.fourthtitle")}>*/}
        {/*            {t("home.t&c.fourthdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.fifthtitle")}>*/}
        {/*            {t("home.t&c.fifthdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.sixthtitle")}>*/}
        {/*            {t("home.t&c.sixththdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.seventhtitle")}>*/}
        {/*            {t("home.t&c.sevenththdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.eighthtitle")}>*/}
        {/*            {t("home.t&c.eighththdesc")}*/}
        {/*          </AccordionComponent>*/}
        {/*          <AccordionComponent title={t("home.t&c.ninethtitle")}>*/}
        {/*            {t("home.t&c.nineththdesc")}*/}
        {/*            <ul>*/}
        {/*              <li>{t("home.t&c.list1")}</li>*/}
        {/*              <li>{t("home.t&c.list2")}</li>*/}
        {/*              <li>{t("home.t&c.list3")}</li>*/}
        {/*              <li>{t("home.t&c.list4")}</li>*/}
        {/*              <li>{t("home.t&c.list5")}</li>*/}
        {/*              <li>{t("home.t&c.list6")}</li>*/}
        {/*            </ul>*/}
        {/*          </AccordionComponent>*/}
        {/*        </Box>*/}
        {/*      </Container>*/}
        {/*    </Box>*/}
        {/*)}*/}
        {/* Default pop up */}
        {popupOpen && popupBanner.result && popupBanner.result.length > 0 && !isPopupBannerFetching
          && !isPopupBannerLoading && isPopupBannerSuccess && (<PopupBanner data={popupBanner} />)}
        {/* <Modal open={popupOpen} onClose={closePopup}>
          <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: mobileView ? 350 : "50%",
                transform: "translate(-50%, -50%)",
                outline: "none",
              }}
              textAlign="right"
          >
            <div sx={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
              <IconButton aria-label="close" onClick={closePopup}>
                <CloseIcon />
              </IconButton>
            </div>
            <Link to={lang === 'en' ? "/en-np/promotion" : "/ne-np/promotion"}>
              <Image
                  src={(lang === 'en' || lang === null) ? process.env.REACT_APP_POPUP_EN : process.env.REACT_APP_POPUP_BD}
                  sx={{ borderRadius: "5%" }}
                  alt={Constant.ALT_TAG_HOME_ADS}
              />
              
            </a> */}

        {/*</Link>*/}
        {/* </Box>
        </Modal> */}

        <Modal open={affiliatePopupOpen} onClose={closeAffiliatePopup}>
          <Box
            sx={{
              minWidth: "350px",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: mobileView ? 350 : null,
              transform: "translate(-50%, -50%)",
              outline: "none",
              color: "white",
              padding: "15px 30px",
              textAlign: "center",
              background: theme.palette.background.paper
            }}
            textAlign="right"
          >
            <IconButton sx={{ position: "absolute", top: 0, right: 0 }} edge="start" onClick={closeAffiliatePopup}>
              <CloseIcon />
            </IconButton>
            <div dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                homePage?.result?.common?.affiliate_content
              ),
            }}
            ></div>

            {!isLoggedIn &&
              <Box sx={{ marginTop: "15px" }}>
                <Button
                  variant="contained"
                  sx={{
                    mr: 1,
                  }}
                  onClick={() => mobileView ? navigate(Constant.getPath("/login")) : closeAffiliatePopup()}
                >
                  {t("header.login")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate(Constant.getPath("/register"))}
                >
                  {t("header.signup")}
                </Button>
              </Box>
            }

            {isLoggedIn && <p>
              <TextField
                label={t("walletcard.myaccountpage.referrallink")}
                value={shareLink?.result?.url}
                InputLabelProps={{ shrink: true }}
                disabled
                type="text"
                size="small"
                sx={{ width: "300px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <CopiedTooltip text={shareLink?.result?.url} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </p>}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Home;
