import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const FormTextField = ({
  control,
  defaultValue,
  label,
  controllerName,
  type,
  size,
  placeholder,
  InputProps,
  disabled,
  InputLabelProps,
  margin,
  style,
  note,
  dynamicValue,
  onChange // Added onChange prop
                       }) => {
  return (
    <FormControl
      sx={
        Object.keys(style).length !== 0
          ? style
          : {
              display: "flex",
              flexDirection: "column",
              mt: "15px",
            }
      }
    >
      <Controller
        name={controllerName}
        control={control}
        defaultValue={dynamicValue ? dynamicValue : defaultValue}
        render={({ field: { onChange:fieldOnChange, value, onBlur:fieldOnBlur }, fieldState: { error } }) => (
          <TextField
            label={label}
            value={dynamicValue ? dynamicValue : value}
            onChange={(e) => {
              fieldOnChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            onBlur={() => {
              if (controllerName === "username") {
                fieldOnChange(value.trim());
              }
              fieldOnBlur();
            }}
            error={!!error}
            helperText={error ? error.message : note}
            type={type}
            size={size}
            placeholder={placeholder}
            disabled={disabled}
            InputLabelProps={InputLabelProps}
            margin={margin}
            fullWidth
            fontSize={"0.5rem"}
          />
        )}
        InputProps={InputProps}
      />
    </FormControl>
  );
};

FormTextField.propTypes = {
  label: PropTypes.string,
  controllerName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  style: PropTypes.object,
  note: PropTypes.string,
};

FormTextField.defaultProps = {
  label: "",
  controllerName: "",
  type: "text",
  size: "medium",
  placeholder: "",
  disabled: false,
  margin: "",
  style: {},
  note: "",
};

export default FormTextField;
